<template>
  <div>
    <v-subheader class="font-weight-bold">{{ title }}</v-subheader>
    <v-list two-line subheader>
      <div class="module-default__about-text ml-4" v-html="fieldValue(model)" />
    </v-list>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    adkType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    }
  },

  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });

    const fieldValue = val => {
      return adkData.value?.fields?.find(f => f.model === val)?.value[val] || '';
    };

    return { programDoc, adkData, fieldValue };
  }
};
</script>
